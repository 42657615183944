import React, { useState } from 'react';
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBInput,
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
} from 'mdb-react-ui-kit';
import './Login.css';
import { useNavigate } from 'react-router-dom';  // Import useNavigate for redirection
import Logo from '../../../assets/logo.png';

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const navigate = useNavigate();  // Instantiate useNavigate

    const handleLogin = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch('api/Login/Login.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ username, password }),
            });

            const result = await response.json();

            if (result.success) {
                setMessage('Login successful!');
                // Save token or login status (if needed)
                localStorage.setItem('auth', true);  // Save login status to localStorage
                navigate('/admin');        // Redirect to admin dashboard
            } else {
                setMessage(result.message || 'Login failed!');
            }
        } catch (error) {
            setMessage('An error occurred. Please try again.');
        }
    };

    return (
        <MDBContainer fluid className="login-container d-flex align-items-center justify-content-center vh-100">
            <MDBCard className="w-50 shadow-5">
                <MDBCardBody className='d-flex align-items-center flex-column'>
                    <img src={Logo} alt="" style={{ height: '150px' }} className='w-auto' />
                    <MDBCardTitle className="text-center text-dark mt-3">Velkommen tilbake</MDBCardTitle>
                    <MDBCardText className="text-center text-muted">
                        Vennligst skriv ditt brukernavn & passord
                    </MDBCardText>

                    <form onSubmit={handleLogin} className='w-75'>
                        <MDBRow>
                            <MDBCol md="12">
                                <MDBInput
                                    label="Username"
                                    id="username"
                                    type="text"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                    required
                                />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className="mt-4">
                            <MDBCol md="12">
                                <MDBInput
                                    label="Password"
                                    id="password"
                                    type="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className="mt-4">
                            <MDBCol md="12" className="text-center">
                                <MDBBtn type="submit" color="primary">
                                    Log In
                                </MDBBtn>
                            </MDBCol>
                        </MDBRow>
                    </form>

                    {message && <p className="text-center text-danger mt-4">{message}</p>}
                </MDBCardBody>
            </MDBCard>
        </MDBContainer>
    );
};

export default Login;
