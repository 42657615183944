import React from 'react'
import {
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
    MDBRow,
    MDBCol,
    MDBIcon,
    MDBAnimation
} from 'mdb-react-ui-kit';
import './Stats.css'

const Stats = () => {
  return (
      <MDBRow className='row-cols-1 flex-wrap h-100 d-flex justify-content-center row-cols-md-3 g-2 stats-desktop'>
          <MDBCol className='col-md-3'>
          <MDBAnimation
              className='p-0 m-0'
              animation='fade-in'
              delay='1400'
              start='onLoad'
              duration={800}
          >
              <MDBCard className='text-center bg-transparent text-white w-75 mx-auto' style={{boxShadow: 'none'}}>
                  <MDBIcon fas size='4x' className='mx-auto my-3 text-secondary' icon="award" />
                  <MDBCardBody>
                      <MDBCardTitle>Produkt</MDBCardTitle>
                      <MDBCardText>
                          Vi bruker bare høykvalitets produkter som sørger for et bedre resultat og lenger varighet
                      </MDBCardText>
                  </MDBCardBody>
              </MDBCard>
          </MDBAnimation>
          </MDBCol>
          <MDBCol className='col-md-1 align-items-center d-flex justify-content-center'>
              <MDBAnimation
                  className='h-100 my-auto d-flex'
                  animation='fade-in'
                  delay='1450'
                  start='onLoad'
                  duration={800}
              >
              <hr className="vr h-50 my-auto" />
              </MDBAnimation>
          </MDBCol>
          <MDBCol className='col-md-3'>
              <MDBAnimation
                  className='h-100 my-auto d-flex'
                  animation='fade-in'
                  delay='1500'
                  start='onLoad'
                  duration={800}
              >
              <MDBCard className='text-center bg-transparent text-white w-75 mx-auto' style={{boxShadow: 'none'}}>
                  <MDBIcon far icon="money-bill-alt" size='4x' className='mx-auto my-3 text-secondary' />
                  <MDBCardBody>
                      <MDBCardTitle>Pris</MDBCardTitle>
                      <MDBCardText>
                          Prisene våres anses som rimlig i forhold til dagens gjennomsnittspris
                      </MDBCardText>
                  </MDBCardBody>
              </MDBCard>
              </MDBAnimation>
          </MDBCol>
          <MDBCol className='col-md-1 align-items-center d-flex justify-content-center'>
              <MDBAnimation
                  className='h-100 my-auto d-flex'
                  animation='fade-in'
                  delay='1550'
                  start='onLoad'
                  duration={800}
              >
            <hr className="vr h-50 my-auto" />
            </MDBAnimation>
          </MDBCol>
          <MDBCol className='col-md-3'>
              <MDBAnimation
                  className='h-100 my-auto d-flex'
                  animation='fade-in'
                  delay='1600'
                  start='onLoad'
                  duration={800}
              >
              <MDBCard className='text-center bg-transparent text-white w-75 mx-auto' style={{boxShadow: 'none'}}>
                  <MDBIcon fas size='4x' className='mx-auto my-3 text-secondary' icon="brain" />
                  <MDBCardBody>
                      <MDBCardTitle>Erfaring</MDBCardTitle>
                      <MDBCardText>
                          Våre behandlere har opptil flere års erfaring, de fleste 6+ år!
                      </MDBCardText>
                  </MDBCardBody>
              </MDBCard>
              </MDBAnimation>
          </MDBCol>
      </MDBRow>
  )
}

export default Stats