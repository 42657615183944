import React from 'react'
import { MDBAnimation, MDBBtn, MDBContainer, MDBTypography, MDBAnimationt } from 'mdb-react-ui-kit'
import Stats from '../stats/Stats'
import './Hero.css';
import Navbar from '../navbar/Navbar';

import Logo from '../../assets/logo_white.png'

const Hero = () => {
  return (
    <>
          <div
        className='text-center bg-image hero_bg-image' id='hero'
          style={{ minHeight: '100vh', height: 'auto', backgroundAttachment: "fixed", backgroundPosition: "bottom", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}
      >
    <Navbar />
              <div className='mask'>
                  <MDBContainer fluid className='text-center justify-content-center mt-4 p-0 d-flex flex-column align-items-center' style={{height: '100vh'}}>
                      <MDBContainer fluid className='hero-wrapper' style={{ position: 'relative' }}>
                          <div className="hero_text-wrapper d-flex flex-column justify-content-center h-100 my-auto">
                          <MDBAnimation
                              className='p-0 m-0'
                              animation='fade-in'
                              delay='300'
                              start='onLoad'
                              duration={800}
                          >
                  <MDBTypography tag="h1" color='white' className='display-1' style={{lineHeight: "120px", fontSize: '8rem' }}>
                                      <span className='fw-bold hero-header' style={{ textShadow: 'rgba(229, 166, 255, 0.8) 0px 0px 26px'}}>GLOW UP</span> 
                  </MDBTypography>
                          </MDBAnimation>
                      <MDBAnimation
                          className='p-0 m-0'
                          animation='fade-in'
                          delay='500'
                          start='onLoad'
                          duration={800}
                      >
                          <MDBTypography tag="h1" color='white' className='display-2' style={{ lineHeight: "40px", fontSize: '4rem' }}>
                              <span className='fw-light hero-sub' style={{ textShadow: 'rgba(255,255,255, 0.5) 0px 0px 16px' }}>AV ALINA</span>
                      </MDBTypography>
                      </MDBAnimation>
                      <MDBAnimation
                          className='p-0 m-0'
                          animation='fade-in'
                          delay='800'
                          start='onLoad'
                          duration={800}
                      >
                  <hr className="hr hr-blurry w-25 bg-white mx-auto" />
                  </MDBAnimation>
                      <MDBAnimation
                          className='p-0 m-0'
                          animation='fade-in'
                          delay='1000'
                          start='onLoad'
                          duration={800}
                      >
                              <p className='text-white mx-auto w-50 fw-light fs-5 p-4 hero_text'>Velkommen til GlowUp, hvor skjønnhet møter perfeksjon. Vi er eksperter på vippe-extensions og tilbyr skreddersydde løsninger som fremhever din naturlige skjønnhet. Med de beste produktene og erfarne stylister, gir vi deg resultatene du drømmer om – alt fra naturlig eleganse til dramatisk volum. La oss hjelpe deg med å føle deg fantastisk hver dag. Bestill din time i dag og opplev forskjellen!</p>
                      </MDBAnimation>
                  <MDBContainer className='d-flex flex-row justify-content-center align-items-center mt-5'>
                          <MDBAnimation
                              className='p-0 m-0'
                              animation='fade-in'
                              delay='1200'
                              start='onLoad'
                              duration={800}
                          >
                      <MDBBtn rippleColor="white" color='primary text-white mx-3 my-auto btn-lg' href='#booking'>Bestill time nå</MDBBtn>
                      </MDBAnimation>
                          <MDBAnimation
                              className='p-0 m-0'
                              animation='fade-in'
                              delay='1400'
                              start='onLoad'
                              duration={800}
                          >
                        <MDBBtn outline rippleColor="white" href='#contact' color='secondary text-white mx-3 btn-lg'>Ta kontakt</MDBBtn>
                      </MDBAnimation>
                  </MDBContainer>
                              {/* <MDBAnimation
                                  className='p-0 m-0'
                                  animation='fade-in'
                                  delay='1500'
                                  start='onLoad'
                                  duration={800}
                              >
                  <img src={Logo} alt="" className='hero_logo mx-auto' style={{height: '150px', width: '150px', marginTop: '5rem'}} />
                  </MDBAnimation> */}
                          </div>
                  </MDBContainer>
              <MDBContainer fluid className='h-auto position-absolute w-auto bottom-0 mb-0 stats-wrapper'>
              {/* <Stats /> */}
              </MDBContainer>
              </MDBContainer>
          </div >
      </div >
      </>
  )
}

export default Hero