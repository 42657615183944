import React, { useState, useEffect } from 'react';
import { MDBInput, MDBBtn, MDBRadio, MDBContainer, MDBSelect, MDBDatepicker, MDBAnimation } from 'mdb-react-ui-kit';
import axios from 'axios';
import './Booking.css';

const Booking = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [selectedDate, setSelectedDate] = useState('');
    const [selectedTime, setSelectedTime] = useState('');
    const [selectedServices, setSelectedServices] = useState([]);
    const [availableTimes, setAvailableTimes] = useState([]);
    const [bookedSlots, setBookedSlots] = useState([]);

    // Service duration mapping (in minutes)
    const serviceDurations = {
        Vipper: 120,
        Negler: 60,
        Hårfarge: 90
    };

    // Service type options
    const selectData = [
        { text: 'Vipper', secondaryText: '800kr', value: 'Vipper' },
        { text: 'Negler', secondaryText: '600kr', value: 'Negler' },
        { text: 'Hårfarge', secondaryText: '900kr', value: 'Hårfarge' }
    ];

    // Fetch booked slots when date changes
    useEffect(() => {
        const fetchBookedSlots = async () => {
            if (selectedDate) {
                try {
                    const response = await axios.get('https://vipperavalina.dencker.no/api/Booking/fetchBookings.php', {
                        params: { date: selectedDate }
                    });
                    setBookedSlots(response.data);
                    setSelectedTime(''); // Clear selected time when the date changes
                } catch (error) {
                    console.error('Error fetching booked slots', error);
                }
            }
        };
        fetchBookedSlots();
    }, [selectedDate]);



    const generateAvailableTimes = () => {
        const start = "08:00";
        const end = "20:00";
        const baseInterval = 30; // Check every 30 minutes for availability
        let times = [];

        // Calculate total duration of the selected services
        const totalDuration = selectedServices.reduce((acc, service) => acc + (serviceDurations[service] || 0), 0);

        // Parse start and end time
        const startTime = new Date(`1970-01-01T${start}`);
        const endTime = new Date(`1970-01-01T${end}`);

        while (startTime < endTime) {
            const timeString = startTime.toISOString().substr(11, 5);

            // Check if the current time slot is available
            const isAvailable = bookedSlots.every(booking => {
                const bookedStartTime = new Date(`1970-01-01T${booking.time}`);
                const bookedServices = booking.job_type.split(', ').map(service => service.trim());
                const bookedDuration = bookedServices.reduce((acc, service) => acc + (serviceDurations[service] || 0), 0);
                const bookedEndTime = new Date(bookedStartTime);
                bookedEndTime.setMinutes(bookedEndTime.getMinutes() + bookedDuration);

                // End time of the selected slot
                const slotEndTime = new Date(startTime);
                slotEndTime.setMinutes(slotEndTime.getMinutes() + totalDuration);

                // Check for overlap with existing bookings
                return (slotEndTime <= bookedStartTime || startTime >= bookedEndTime);
            });

            if (isAvailable) {
                times.push(timeString);
            }

            startTime.setMinutes(startTime.getMinutes() + baseInterval);
        }

        setAvailableTimes(times);
    };

    // Generate available times when services or date changes
    useEffect(() => {
        if (selectedServices.length > 0 && selectedDate) {
            generateAvailableTimes();
        }
    }, [selectedServices, selectedDate, bookedSlots]);


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const bookingData = {
                name,
                email,
                phone,
                date: selectedDate,
                time: selectedTime,
                job_type: selectedServices.join(', ')
            };

            const response = await axios.post('https://vipperavalina.dencker.no/api/Booking/booking.php', bookingData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (response.status === 200) {
                alert('Booking successful!');
            }
        } catch (error) {
            console.error('There was an error booking the appointment!', error);
            alert('Failed to book the appointment. Please try again later.');
        }
    };

    // Get the current date
    const today = new Date();

    // Filter function to disable specific dates
    const filterFunction = (date) => {
        const isSaturday = date.getDay() === 5;
        const isSunday = date.getDay() === 6;
        const isToday = date.toDateString() === today.toDateString();

        // Disable current day and weekends
        return !isToday && !isSaturday && !isSunday;
    };

    return (
        <MDBContainer fluid className='bg-dark' id='booking'>
            <MDBContainer className='d-flex flex-row w-100 booking-wrapper py-5'>
                <div className="d-flex flex-column me-5 my-auto text-wrapper" style={{ flex: '1.5' }}>
                    <MDBAnimation
                        reset={true}
                        animation='fade-in'
                        delay='400'
                        start='onScroll'
                        duration={500}
                    >
                        <h2 className='display-6 fw-bold text-primary'>Bestill time</h2>
                    </MDBAnimation>
                    <MDBAnimation
                        reset={true}
                        animation='fade-in'
                        delay='600'
                        start='onScroll'
                        duration={500}
                    >
                        <p className="fs-6 my-4 text-light lead">Ønsker du å forvandle blikket ditt med vakre, velpleide vipper? Bestill en time hos vår profesjonelle vippeteknikker i dag! Vi tilbyr skreddersydde vippeløsninger som gir deg et uimotståelig, naturlig resultat – perfekt for både hverdag og spesielle anledninger. Våre erfarne teknikere bruker kun de beste produktene og metoder for å sikre at du får et holdbart og elegant resultat. Book din time nå for å oppleve luksusen av perfekt fremhevede vipper og la oss gi deg den gløden du fortjener.</p>
                    </MDBAnimation>
                </div>
                <div className="d-flex w-100 mx-auto text-light" style={{ flex: '2' }}>
                    <form onSubmit={handleSubmit} className="my-2 w-100">
                        {/* Personal Details */}
                        <MDBAnimation
                            reset={true}
                            animation='fade-in'
                            delay='400'
                            start='onScroll'
                            duration={500}
                        >
                            <MDBInput className="my-3" labelClass='text-light' label="Navn" value={name} onChange={(e) => setName(e.target.value)} required />
                        </MDBAnimation>
                        <MDBAnimation
                            reset={true}
                            animation='fade-in'
                            delay='400'
                            start='onScroll'
                            duration={500}
                        >
                            <MDBInput className="my-3" labelClass='text-light' label="E-post" value={email} onChange={(e) => setEmail(e.target.value)} required />
                        </MDBAnimation>
                        <MDBAnimation
                            reset={true}
                            animation='fade-in'
                            delay='400'
                            start='onScroll'
                            duration={500}
                        >
                            <MDBInput className="my-3" labelClass='text-light' label="Telefon" value={phone} onChange={(e) => setPhone(e.target.value)} required />
                        </MDBAnimation>

                        {/* Service Type Selection */}
                        <MDBAnimation
                            reset={true}
                            animation='fade-in'
                            delay='400'
                            start='onScroll'
                            duration={500}
                        >
                            <MDBSelect
                                labelClass='text-light'
                                label='Velg tjeneste'
                                data={selectData}
                                multiple
                                className="mb-3"
                                value={selectedServices}
                                onValueChange={(e) => {
                                    if (Array.isArray(e) && e.length > 0) {
                                        setSelectedServices(e.map(option => option.value));
                                    } else {
                                        setSelectedServices([]);
                                    }
                                }}
                            />
                        </MDBAnimation>

                        {/* Date Selection */}
                        <MDBAnimation
                            reset={true}
                            animation='fade-in'
                            delay='400'
                            start='onScroll'
                            duration={500}
                        >
                            <MDBDatepicker
                                disablePast
                                filter={filterFunction}
                                inline
                                inputToggle
                                value={selectedDate}
                                onChange={(date) => setSelectedDate(date)}
                                label='Velg dato'
                                monthsFull={[
                                    'Januar', 'Februar', 'Mars', 'April', 'Mai', 'Juni',
                                    'Juli', 'August', 'September', 'Oktober', 'November', 'Desember'
                                ]}
                                monthsShort={['Jan', 'Feb', 'Mar', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Des']}
                                weekdaysFull={['Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lørdag', 'Søndag']}
                                weekdaysShort={['Man', 'Tir', 'Ons', 'Tor', 'Fre', 'Lør', 'Søn']}
                                weekdaysNarrow={['M', 'T', 'O', 'T', 'F', 'L', 'S']}
                                okBtnText='Velg'
                                clearBtnText='Fjern valgt'
                                cancelBtnText='Avbryt'
                                format='yyyy-mm-dd'
                                labelClass='text-light'
                            />
                        </MDBAnimation>

                        {/* Time Selection */}
                        <MDBAnimation
                            reset={true}
                            animation='fade-in'
                            delay='400'
                            start='onScroll'
                            duration={500}
                        >
                            <MDBContainer className='mb-3 my-3 w-100 d-flex flex-wrap radio_wrapper' aria-label='Available Times'>
                                {availableTimes.length > 0 ? (
                                    availableTimes.map((time, index) => (
                                        <MDBRadio
                                            key={index}
                                            name="availableTimes"
                                            id={`time-${index}`}
                                            value={time}
                                            label={time}
                                            checked={selectedTime === time}
                                            onChange={() => setSelectedTime(time)}
                                            wrapperClass="me-2 my-2" // Add some spacing between the radio buttons
                                        />
                                    ))
                                ) : (
                                    <p>Ingen ledige tider.</p>
                                )}
                            </MDBContainer>
                        </MDBAnimation>

                        <MDBBtn type="submit">Bestill time</MDBBtn>
                    </form>
                </div>
            </MDBContainer>
        </MDBContainer>
    );
};

export default Booking;
