// src/container/home/Home.js
import React from 'react';
import Hero from '../../components/hero/Hero';
import BookingForm from '../../components/booking/Booking';
import Calendar from '../../components/Calendar';
import About from '../../components/about/About'
import Gallery from '../../components/gallery/Gallery.jsx';
import Team from '../../components/team/Team';
import CallToAction from '../../components/callToAction/CallToAction';
import Footer from '../../components/footer/Footer';
import './Home.css';
import CustomerRating from '../../components/customerRating/CustomerRating';

const Home = ({ onBookingSubmit }) => {
    return (
        <>
            <Hero />
            <About />
            <Gallery />
            <CallToAction />
            <CustomerRating />
            <Team />
            <BookingForm onBookingSubmit={onBookingSubmit} />
            <Footer />
            {/* <Calendar bookings={bookings}/> */}
        </>
    );
};

export default Home;
