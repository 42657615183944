import { MDBCalendar } from 'mdb-react-calendar'
import { MDBBtn, MDBBtnGroup, MDBCol, MDBContainer, MDBRow, MDBTypography } from 'mdb-react-ui-kit'
import React from 'react'
import Register from '../../components/register/Register'
import AdminCalendar from '../adminDashboard/adminCalendar/AdminCalendar'
import './AdminHome.css'

const AdminHome = () => {
  return (
        <MDBContainer fluid className='adminHome-wrapper' style={{height: '100vh'}}>
          <MDBContainer className='position-relative bg-glass p-5 my-auto' style={{ top: '150px' }}>
              <MDBTypography tag='h3' className='text-dark'>
                  Velkommen tilbake, (brukernavn).
              </MDBTypography>
              <p className='text-muted my-4'>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ad, ipsam maxime! Veniam rem amet cupiditate tempore at, id quasi ex minima perferendis quia. Laudantium, eum. Velit nulla officiis ut rerum.</p>
              <MDBBtn className='m-2 btn-lg'>Endre passord</MDBBtn>
              <MDBBtn className='m-2 btn-lg'>Se bookinger</MDBBtn>
          </MDBContainer>
          <MDBRow className='w-100 position-relative my-5' style={{ top: '150px' }}>
                <MDBCol md={5} className='my-2'>
              <MDBContainer className='h-100'>
                  <Register className='position-relative' style={{ top: '180px' }} />
              </MDBContainer>
                  </MDBCol>
                  <MDBCol md={7} className='my-2'>
                    <MDBContainer className='p-4 bg-light'>
        <AdminCalendar />
                  </MDBContainer>
                  </MDBCol>
              </MDBRow>
        </MDBContainer>
  )
}

export default AdminHome