import { MDBBtn, MDBCard, MDBCardBody, MDBCardText, MDBCardTitle, MDBCol, MDBContainer, MDBRow, MDBAnimation, MDBInput, MDBCheckbox, MDBTextArea } from 'mdb-react-ui-kit'
import StatsMobile from '../statsMobile/StatsMobile'
import './CallToAction.css';

const CallToAction = () => {
  return (
    <MDBContainer fluid className='p-5 CTA' id='contact'>
      <MDBRow className='CTA_wrapper bg-glass'>
        <MDBCol md="6" className='CTA_text-wrapper py-5'>
            <MDBCardBody className='p-5'>
            <MDBAnimation
              reset={true}
              animation='fade-in'
              delay='400'
              start='onScroll'
              duration={500}
            >
              <MDBCardTitle className='text-primary fs-2 my-4 fw-bolder'>Ta kontakt med oss <br /> i dag!</MDBCardTitle>
              </MDBAnimation>
            <MDBAnimation
              reset={true}
              animation='fade-in'
              delay='400'
              start='onScroll'
              duration={500}
            >
              <MDBCardText className='fs-5 w-75 text-dark CTA_Text'>Unn deg selv litt ekstra luksus og gi vippene dine den behandlingen de fortjener. Våre profesjonelle stylister er klare til å gi deg drømmevippene som både ser fantastiske ut og føles naturlige. <br /> Ta det første steget mot et nytt, vakkert blikk i dag. Ta enten kontakt med hjelp av skjema, eller trykk på knappen for å bestille time og sikre deg en plass hos våre dyktige stylister. Dine perfekte vipper er bare et klikk unna!</MDBCardText>
            </MDBAnimation>
            </MDBCardBody>
        </MDBCol>
        <MDBCol md="6" className='cta-img-wrapper p-5'>
          <form className='my-auto form-glass p-5'>
            <MDBAnimation
              reset={true}
              animation='fade-in'
              delay='500'
              start='onScroll'
              duration={500}
            >
              <MDBInput id='form4Example1' wrapperClass='mb-4' labelClass='text-dark' label='Navn' />
            </MDBAnimation>
            <MDBAnimation
              reset={true}
              animation='fade-in'
              delay='550'
              start='onScroll'
              duration={500}
            >
              <MDBInput type='email' id='form4Example2' wrapperClass='mb-4' labelClass='text-dark' label='E-post' />
            </MDBAnimation>
            <MDBAnimation
              reset={true}
              animation='fade-in'
              delay='600'
              start='onScroll'
              duration={500}
            >
              <MDBTextArea wrapperClass='mb-4' style={{ resize: 'none' }} labelClass='text-dark' textarea id='form4Example3' rows={4} label='Melding'>
              </MDBTextArea>
            </MDBAnimation>
            <div className="my-5">
              <MDBBtn type='submit' size='lg' className='text-light my-1 shadow-md mx-2'>
                Send melding
              </MDBBtn>
              <MDBBtn type='submit' color='primary' outline size='lg' className='text-primary my-1 shadow-md mx-2'>
                Bestill time
              </MDBBtn>
            </div>
          </form>
            <MDBCardBody className='p-2 h-100 my-auto w-auto mt-4'>
            {/* <StatsMobile /> */}
            </MDBCardBody>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  )
}

export default CallToAction