import React from 'react';
import { MDBBtn, MDBCard, MDBCardBody, MDBCardText, MDBCardTitle, MDBCol, MDBContainer, MDBRow, MDBAnimation, MDBTypography } from 'mdb-react-ui-kit';

import './About.css'

import Maps from '../../assets/maps.avif'
import Lashes from '../../assets/lashes.avif'
import Bank from '../../assets/bank.avif'

const About = () => {
  return (
    <MDBContainer fluid className="m-0 p-0" id='about'>
      <MDBRow className='w-100'>
        {/* Hero Section */}
        <MDBAnimation
          reset={true}
          animation='fade-in'
          delay='400'
          start='onScroll'
          duration={500}
        >
          <MDBCol md="12" className="text-center my-4">
            <h1 className="text-uppercase fs-4">Historien bak <br /> <span className='text-primary fw-bolder display-2'>GLOW UP</span></h1>
            <p className="lead">Bli kjent med oss og våre verdier</p>
          </MDBCol>
        </MDBAnimation>
      </MDBRow>
      <MDBContainer fluid className='justify-content-center framed my-5 d-flex rounded flex-column bg-secondary align-items-center text-center about_bg-image' style={{width: '90%'}}>
        {/* Team Section */}
        <MDBContainer md="6" className='my-5 d-flex justify-content-center w-75 about__img-wrapper'>
          <MDBContainer id='about_glass' className="bg-glass-dark shadow-5-strong p-5 mt-5 position-relative" style={{top: '180px'}}>
          <MDBAnimation
            reset={true}
            animation='fade-in'
            delay='400'
            start='onScroll'
            duration={500}
          >

            <MDBTypography tag='h3' className='fw-bold text-light display-5 mb-4'>Vår historie</MDBTypography>
              <hr className="hr hr-blurry" />
            <MDBTypography className='w-100 mx-auto'>
              Vi startet denne salongen med en lidenskap for skjønnhet og et ønske om å gi våre kunder mer enn bare flotte vipper – vi ønsket å tilby en helhetlig opplevelse. Fra det øyeblikket du setter foten inn i vår salong, er det vår misjon å få deg til å føle deg avslappet, ivaretatt og fornøyd med resultatet. Vår reise begynte som et lite lokalt studio, og har med tiden vokst til å bli et av de mest anerkjente navnene innen vippe-extensions i området. Vi har bygget et rykte på å lytte til våre kunder, levere kvalitet og sørge for at hver kunde går ut døren med et smil. For oss er dette mer enn bare en jobb – det er vår lidenskap.
            </MDBTypography>
          </MDBAnimation>
          </MDBContainer>
        </MDBContainer>
      </MDBContainer>
    </MDBContainer>
  );
}

export default About;
