// src/App.js
import { MDBContainer } from 'mdb-react-ui-kit';
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './container/home/Home';
import AdminPanel from './container/adminDashboard/AdminPanel';
import Login from './container/adminDashboard/login/Login';
import AdminCalendar from './container/adminDashboard/adminCalendar/AdminCalendar';
import Register from './components/register/Register';

const App = () => {
  const [bookings, setBookings] = useState([]);

  const handleBookingSubmit = (booking) => {
    setBookings([...bookings, booking]);
  };

  return (
    <MDBContainer fluid className='p-0 bg-secondary m-0 w-100'>
      <Router>
        <Routes>
          <Route
            path="/"
            element={<Home onBookingSubmit={handleBookingSubmit} bookings={bookings} />}
          />
          <Route path="/login" element={<Login />} />
          <Route path="/admin" element={<AdminPanel />} />
          <Route path="/admin/booking" element={<AdminCalendar />} />
          <Route path="/admin/register" element={<Register />} />
        </Routes>
      </Router>
    </MDBContainer>
  );
};

export default App;
