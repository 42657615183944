import React from 'react';
import {
    MDBFooter,
    MDBContainer,
    MDBCol,
    MDBRow,
    MDBIcon,
    MDBBtn
} from 'mdb-react-ui-kit';

const Footer = () => {
  return (
      <MDBFooter className='text-center text-white bg-light bg-opacity-50'>

          <div className='text-center text-light p-3 bg-dark' style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
              © 2024 -
              <a className='text-light' href='https://google.com/'> GlowUpByAlina.com</a>


              <MDBContainer className='pt-2'>
                  <section className='mb-2'>
                      <MDBBtn
                          rippleColor="dark"
                          color='link'
                          floating
                          size="lg"
                          className='text-light m-1'
                          href='#!'
                          role='button'
                      >
                          <MDBIcon fab className='fa-tiktok' />
                      </MDBBtn>

                      <MDBBtn
                          rippleColor="dark"
                          color='link'
                          floating
                          size="lg"
                          className='text-light m-1'
                          href='#!'
                          role='button'
                      >
                          <MDBIcon fab className='fa-instagram' />
                      </MDBBtn>
                  </section>
              </MDBContainer>
          </div>
      </MDBFooter>
  )
}

export default Footer