import React from 'react'
import { MDBCarousel, MDBCarouselItem, MDBCol, MDBContainer, MDBRow, MDBTypography } from 'mdb-react-ui-kit';
import './CustomerRating.css'

const CustomerExperience = () => {
  return (
    <MDBCarousel className='p-5' showIndicators interval={5000}>
      <MDBCarouselItem itemId={1} className="my-4">
            <MDBContainer>
          <MDBRow className='CR_Row p-5'>
        <MDBCol size={6} className='p-5 m-0' style={{rotate: '3deg'}}>
              <img id='testimonial_image' src='https://www.seher.no/images/80577553.jpg?imageId=80577553&panow=72.413793103448&panoh=19.559228650138&panox=18.965517241379&panoy=25.619834710744&heightw=32.142857142857&heighth=42.363112391931&heightx=32.857142857143&heighty=22.478386167147&width=1200&height=1200' className='w-100 mx-auto rounded' alt='...' />
          </MDBCol>
        <MDBCol size={6} className='p-3 bg-glass m-0'>
              <MDBContainer className='h-100 w-100 flex-column d-flex justify-content-center'>
          <h1 className='display-2 fw-bold'>Beste vippe-teknikker i Oslo</h1>
                <MDBTypography className='lead text-dark' tag='p'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Provident asperiores laboriosam laudantium. Vel molestiae provident explicabo sint possimus sapiente soluta deleniti vero asperiores est quasi enim, reprehenderit in quis quisquam?</MDBTypography>
                <figcaption className='blockquote-footer mb-0 text-dark'>
                  Isabel Raad <cite title='Source Title'>Paradise Hotell / Vlogger</cite>
                </figcaption>
              </MDBContainer>
        </MDBCol>
    </MDBRow>
          </MDBContainer>
      </MDBCarouselItem>
      <MDBCarouselItem itemId={2} className="my-4">
        <MDBContainer>
          <MDBRow className='CR_Row p-5'>
            <MDBCol size={6} className='p-5 m-0' style={{rotate: '-3deg'}}>
              <img id='testimonial_image' src='https://yt3.googleusercontent.com/ytc/AIdro_laqAMWrGz1bFzm4diu8wmP0o4c-YFkfjqat_0TM9ppu14=s900-c-k-c0x00ffffff-no-rj' className='w-100 mx-auto rounded' alt='...' />
            </MDBCol>
            <MDBCol size={6} className='p-3 bg-glass m-0'>
              <MDBContainer className='h-100 w-100 flex-column d-flex justify-content-center'>
                <h1 className='display-2 fw-bold'>Min nye faste teknikker</h1>
                <MDBTypography className='lead text-dark' tag='p'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Provident asperiores laboriosam laudantium. Vel molestiae provident explicabo sint possimus sapiente soluta deleniti vero asperiores est quasi enim, reprehenderit in quis quisquam?</MDBTypography>
                <figcaption className='blockquote-footer mb-0 text-dark'>
                  Isabell Eriksen <cite title='Source Title'>Influenser / Vlogger</cite>
                </figcaption>
              </MDBContainer>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </MDBCarouselItem>
      <MDBCarouselItem itemId={3} className="my-4">
        <MDBContainer>
          <MDBRow className='CR_Row p-5'>
            <MDBCol size={6} className='p-5 m-0' style={{rotate: '3deg'}}>
              <img id='testimonial_image' src='https://media.licdn.com/dms/image/D4D03AQGMqR4y2B3Wig/profile-displayphoto-shrink_200_200/0/1694673725218?e=2147483647&v=beta&t=VTquVtqpXURD2R-2rr84yE-kywkL16yyjtE84CNn0TA' className='w-100 mx-auto rounded' alt='...' />
            </MDBCol>
            <MDBCol size={6} className='p-3 bg-glass m-0'>
              <MDBContainer className='h-100 w-100 flex-column d-flex justify-content-center'>
                <h1 className='display-2 fw-bold'>Jeg bare er her</h1>
                <MDBTypography className='lead text-dark' tag='p'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Provident asperiores laboriosam laudantium. Vel molestiae provident explicabo sint possimus sapiente soluta deleniti vero asperiores est quasi enim, reprehenderit in quis quisquam?</MDBTypography>
                <figcaption className='blockquote-footer mb-0 text-dark'>
                  Mathias Dencker <cite title='Source Title'>Ektemann</cite>
                </figcaption>
              </MDBContainer>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </MDBCarouselItem>
    </MDBCarousel>
  )
}

export default CustomerExperience