import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBCardTitle, MDBCardText, MDBAnimation } from 'mdb-react-ui-kit';
import './Team.css'

const teamMembers = [
    {
        name: 'Alina Ali',
        position: 'Eier & Stylist',
        description: 'Med over 10 års erfaring i bransjen, er Alina vår leder med en lidenskap for detaljer og stil.',
        image: 'https://marketplace.canva.com/EAFZpF5AO4g/1/0/1600w/canva-orange-white-minimalist-facebook-profile-picture-_19BSgecDak.jpg'
    },
    {
        name: 'Lisa Johansen',
        position: 'Senior Stylist',
        description: 'Lisa er kjent for sin kreative tilnærming og evne til å skape fantastiske vippeopplevelser.',
        image: 'https://dx5683gi1tv0w.cloudfront.net/dtrjyhj9q/image/upload/w_720/s3/img04mpwm7wy'
    },
    {
        name: 'Emil Berg',
        position: 'Junior Stylist',
        description: 'Vårt nyeste tilskudd med øye for de siste trendene og teknikkene. Alltid klar for nye utfordringer!',
        image: 'https://marketplace.canva.com/EAFHfL_zPBk/1/0/1600w/canva-yellow-inspiration-modern-instagram-profile-picture-kpZhUIzCx_w.jpg'
    }
];

const Team = () => {
    return (
        <MDBContainer fluid className=" team-wrapper p-5 bg-light rounded text-dark">
            <MDBRow className="text-center mb-4 p-5 mx-auto bg-glass">
                <MDBCol md="12">
                    <MDBAnimation
                        reset={true}
                        animation='fade-in'
                        delay='400'
                        start='onScroll'
                        duration={500}
                    >
                        <h1 className="fw-bolder mb-4 text-primary fs-4 text-uppercase">Menneskene bak <br /> <span className='text-light fw-bolder display-2'>GlowUp</span></h1>
                    </MDBAnimation>
                    <MDBAnimation
                        reset={true}
                        animation='fade-in'
                        delay='400'
                        start='onScroll'
                        duration={500}
                    >
                    <hr className='hr-blurry hr'/>
                        <p className="lead">Møt våre erfarne og lidenskapelige stylister som jobber hardt for å gi deg den beste vippetjenesten. <br />
                            Vi er dedikerte til å holde oss oppdatert på de nyeste trendene og teknikkene.</p>
                    </MDBAnimation>
                </MDBCol>
            </MDBRow>
            <MDBContainer className='p-5'>
            <MDBRow>
                {teamMembers.map((member, index) => (
                    <MDBCol md="4" key={index} className="mb-5 p-1 mx-auto team_card">
                        <MDBAnimation
                            reset={true}
                            animation='fade-in'
                            delay='400'
                            start='onScroll'
                            duration={500}
                        >
                            <MDBCard className='p-3 mx-2 bg-glass'>
                            <MDBCardBody className="text-center">
                                <img
                                    src={member.image}
                                    alt={member.name}
                                    className="img-fluid rounded-circle mb-3 shadow-4-strong"
                                    style={{ width: '150px', height: '150px', objectFit: 'cover' }}
                                />
                                <MDBCardTitle className="font-weight-bold">{member.name}</MDBCardTitle>
                                <MDBCardText className="font-italic">{member.position}</MDBCardText>
                                <MDBCardText>{member.description}</MDBCardText>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBAnimation>
                    </MDBCol>
                ))}
            </MDBRow>
            </MDBContainer>
        </MDBContainer>
    );
}

export default Team;
