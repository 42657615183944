import React from 'react'
import { Navigate } from 'react-router-dom';
import AdminNavbar from './adminNavbar/AdminNavbar'
import AdminHome from '../adminHome/AdminHome';

const AdminPanel = () => {
    const isAuth = localStorage.getItem('auth');

    // If not authenticated, redirect to login
    if (!isAuth) {
        return <Navigate to="/login" />;
    }
  return (
      <div className='bg-light'>
              <AdminNavbar />
              <AdminHome />
      </div>

  )
}

export default AdminPanel