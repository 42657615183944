import React, { useState, useEffect } from 'react';
import {
    MDBNavbar,
    MDBContainer,
    MDBNavbarNav,
    MDBNavbarItem,
    MDBNavbarLink,
    MDBCollapse,
    MDBNavbarBrand,
    MDBBtn,
} from 'mdb-react-ui-kit';
import { Divide as Hamburger } from 'hamburger-react';
import './Navbar.css';
import Logo from '../../assets/logo.png';

const Navbar = () => {
    const [openNavCentred, setOpenNavCentred] = useState(false);
    const [navbarBackground, setNavbarBackground] = useState(false);

    const handleScroll = () => {
        const heroSection = document.querySelector('#hero');
        if (window.innerWidth > 992 && heroSection) {
            const heroHeight = heroSection.offsetHeight;
            const triggerHeight = heroHeight - 65; // 65px before the end of the hero section
            const shouldSetBackground = window.scrollY > triggerHeight;

            if (navbarBackground !== shouldSetBackground) {
                setNavbarBackground(shouldSetBackground);
            }
        }
    };

    const handleResize = () => {
        if (window.innerWidth <= 992) {
            setNavbarBackground(true);  // Force bg-light when width <= 992px
        } else {
            handleScroll();  // Run the scroll logic if width > 992px
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleResize);

        handleResize(); // Initial check on component mount

        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleResize);
        };
    }, [navbarBackground]);

    return (
        <div className={`align-items-center w-100 ${navbarBackground ? 'position-fixed' : 'bg-transparent top-0 position-relative text-primary shadow-none'}`} style={{zIndex: '1'}}>
                <MDBNavbar expand="lg" className={`w-100 px-3 mx-auto nav-wrapper nav ${navbarBackground ? 'bg-glass shadow-5-strong' : 'bg-transparent text-primary shadow-none'}`} style={{top: '20px', zIndex: '1'}}>
                    <MDBNavbarBrand href="#" className={`ms-4 my-auto position-fixed ${navbarBackground ? 'd-flex' : 'd-none'}`} style={{ zIndex: '2' }}>
                        <img src={Logo} height="80" alt="" loading="lazy" className="mx-auto my-auto" />
                        <span className='brand-text'>Glowup Lashes</span>
                    </MDBNavbarBrand>
                    <MDBContainer fluid className="d-flex flex-row justify-content-end">
                        <div className="d-lg-none position-relative" style={{ zIndex: '2' }}>
                            <Hamburger toggled={openNavCentred} toggle={setOpenNavCentred} />
                        </div>
                        <>
                            {openNavCentred && (
                                <div className={`navbar-menu position-fixed p-1 ${navbarBackground ? '' : 'bg-dark bg-opacity-50 text-light shadow-none'}`} style={{ top: '80px' }}>
                                    <MDBNavbarNav fullWidth={true} className="mb-0 p-5 mb-lg-0 mx-auto">
                                        <MDBNavbarItem>
                                            <MDBNavbarLink aria-current="page" href="#top" className={`mx-2 text-uppercase ${navbarBackground ? 'text-dark' : 'text-light'}`}>
                                                Hjem
                                            </MDBNavbarLink>
                                        </MDBNavbarItem>
                                        <MDBNavbarItem>
                                            <MDBNavbarLink aria-current="page" href="#about" className={`mx-2 text-uppercase ${navbarBackground ? 'text-dark' : 'text-light'}`}>
                                                Om oss
                                            </MDBNavbarLink>
                                        </MDBNavbarItem>
                                        <MDBNavbarItem>
                                            <MDBNavbarLink aria-current="page" href="#booking" className={`mx-2 text-uppercase ${navbarBackground ? 'text-dark' : 'text-light'}`}>
                                                Bestill time
                                            </MDBNavbarLink>
                                        </MDBNavbarItem>
                                        <MDBNavbarItem>
                                            <MDBNavbarLink aria-current="page" href="#contact" className={`mx-2 text-uppercase ${navbarBackground ? 'text-dark' : 'text-light'}`}>
                                                Kontakt
                                            </MDBNavbarLink>
                                        </MDBNavbarItem>
                                    </MDBNavbarNav>
                                </div>
                            )}
                        </>
                        <MDBCollapse navbar show={openNavCentred} className="d-lg-flex justify-content-center my-3">
                            <MDBNavbarNav fullWidth={true} className="mb-2 mb-lg-0 mx-auto">
                                <MDBNavbarItem>
                                    <MDBNavbarLink active aria-current="page" href="#top" className={`mx-2 text-uppercase ${navbarBackground ? 'text-dark' : 'text-light hover-zoom'}`}>
                                        Home
                                    </MDBNavbarLink>
                                </MDBNavbarItem>
                                <MDBNavbarItem>
                                    <MDBNavbarLink active aria-current="page" href="#about" className={`mx-2 text-uppercase ${navbarBackground ? 'text-dark' : 'text-light'}`}>
                                        Om oss
                                    </MDBNavbarLink>
                                </MDBNavbarItem>
                                <MDBNavbarItem>
                                    <MDBNavbarLink active aria-current="page" href="#booking" className={`mx-2 text-uppercase ${navbarBackground ? 'text-dark' : 'text-light'}`}>
                                        Bestill time
                                    </MDBNavbarLink>
                                </MDBNavbarItem>
                                <MDBNavbarItem>
                                    <MDBNavbarLink active aria-current="page" href="#contact" className={`mx-2 text-uppercase ${navbarBackground ? 'text-dark' : 'text-light'}`}>
                                        Kontakt
                                    </MDBNavbarLink>
                                    </MDBNavbarItem>
                            </MDBNavbarNav>
                        </MDBCollapse>
                    </MDBContainer>
                </MDBNavbar>
            </div>
    );
};

export default Navbar;
