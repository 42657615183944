import React, { useState } from 'react';
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBInput,
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
} from 'mdb-react-ui-kit';
import './Register.css';
import Logo from '../../assets/logo.png'

const Register = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');

    const handleRegister = async (e) => {
        e.preventDefault();

        if (password !== confirmPassword) {
            setMessage('Passwords do not match!');
            return;
        }

        try {
            const response = await fetch('api/Register/Register.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ username, password }),
            });

            const result = await response.json();

            if (result.success) {
                setMessage('Registration successful!');
            } else {
                setMessage(result.message || 'Registration failed!');
            }
        } catch (error) {
            setMessage('An error occurred. Please try again.');
        }
    };

    return (
        <MDBContainer className="register-container d-flex h-100">
            <MDBCard className="w-50 shadow-5">
                <MDBCardBody className='d-flex align-items-center flex-column'>
                    <img src={Logo} alt="" style={{ height: '150px' }} className='w-auto' />
                    <MDBCardTitle className="text-center text-dark mt-3">Opprett ny bruker</MDBCardTitle>
                    <MDBCardText className="text-center text-muted">
                        Vennligst fyll ut den etterspurte informasjonen
                    </MDBCardText>

                    <form onSubmit={handleRegister} className='w-75'>
                        <MDBRow>
                            <MDBCol md="12">
                                <MDBInput
                                    label="Username"
                                    id="username"
                                    type="text"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                    required
                                />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className="mt-4">
                            <MDBCol md="12">
                                <MDBInput
                                    label="Password"
                                    id="password"
                                    type="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className="mt-4">
                            <MDBCol md="12">
                                <MDBInput
                                    label="Confirm Password"
                                    id="confirmPassword"
                                    type="password"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    required
                                />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className="mt-4">
                            <MDBCol md="12" className="text-center">
                                <MDBBtn type="submit" color="primary">
                                    Register
                                </MDBBtn>
                            </MDBCol>
                        </MDBRow>
                    </form>

                    {message && <p className="text-center text-danger mt-4">{message}</p>}
                </MDBCardBody>
            </MDBCard>
        </MDBContainer>
    );
};

export default Register;
