import React from 'react'
import { MDBLightbox, MDBLightboxItem, MDBRow, MDBCol, MDBContainer, MDBTypography, MDBAnimation } from 'mdb-react-ui-kit';

const gallery = () => {
  return (
    <MDBContainer className='mb-5 bg-secondary' style={{ marginTop: '15rem' }}>
      <MDBTypography tag='h3' className='display-3 fw-bolder text-light text-uppercase'>
        <MDBAnimation delay={200} start='onScroll' duration={400} animation='fade-in'>
        Se vårt <br /> 
        </MDBAnimation>
        <MDBAnimation delay={300} start='onScroll' duration={400} animation='fade-in'>
        <span className='text-white position-sticky p-1' style={{left: '90%'}}>tidligere arbeid</span>
        </MDBAnimation>
        </MDBTypography>
    <MDBLightbox>
      <MDBRow>
        <MDBCol lg='6'>
            <MDBAnimation delay={200} start='onScroll' duration={400} animation='fade-in'>
          <MDBLightboxItem
              src='https://images.unsplash.com/photo-1589710751893-f9a6770ad71b?q=80&w=3687&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
              fullscreenSrc='https://images.unsplash.com/photo-1589710751893-f9a6770ad71b?q=80&w=3687&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
            className='w-100 mb-2 mb-md-4'
          />
          </MDBAnimation>
        </MDBCol>
        <MDBCol lg='6'>
            <MDBAnimation delay={300} start='onScroll' duration={400} animation='fade-in'>
          <MDBLightboxItem
              src='https://images.unsplash.com/photo-1639629509821-c54cdd984227?q=80&w=3501&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
              fullscreenSrc='https://images.unsplash.com/photo-1639629509821-c54cdd984227?q=80&w=3501&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
            className='w-100 my-2'
          />
          </MDBAnimation>
            <MDBAnimation delay={200} start='onScroll' duration={400} animation='fade-in'>
            <MDBLightboxItem
              src='https://images.unsplash.com/photo-1633346152343-5486573d3d50?q=80&w=3503&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
              fullscreenSrc='https://images.unsplash.com/photo-1633346152343-5486573d3d50?q=80&w=3503&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
              className='w-100 mb-2 mb-md-4'
            />
            </MDBAnimation>
        </MDBCol>
      </MDBRow>
    </MDBLightbox>
  </MDBContainer>
  )
}

export default gallery